import {
	CBSupportedCurrency,
	DefaultPartnerAlignPrivateRoute,
	VendorUserRole,
	ZomentumRoutes,
	ZomentumStorageKeys
} from "@zomentum/contracts";
import {
	DocumentApproval,
	DocumentApproverType
} from "@zomentum/contracts/dist/DocumentApprovals";
import { Document, QuoteTotal } from "@zomentum/contracts/dist/Documents";
import {
	AddQuoteProductCatalogDrawerFormValues,
	EditQuoteProductCatalogDrawerFormValues
} from "@zomentum/contracts/dist/ProposalBuilder/Block";
import { ApprovalStatus } from "@zomentum/contracts/dist/Documents/Revision";
import { AuthToken } from "@zomentum/contracts/dist/Login";
import {
	ActivityMessage,
	ActivityMessageType
} from "@zomentum/contracts/dist/Others/RecentActivityFeed";
import {
	ProductSearchRequest,
	ProductSearchResponse,
	ProductSource,
	ProductType
} from "@zomentum/contracts/dist/Products";
import {
	DistributorRegions,
	EtilizeLocale,
	IngramRegions,
	SimilarEtilzieRegions
} from "@zomentum/contracts/dist/Settings";
import {
	AdminRoleOnly,
	TaxCategory,
	UserCompany,
	UserData
} from "@zomentum/contracts/dist/Users";
import {
	emailRegex,
	getQueryParamAuthDetails
} from "@zomentum/contracts/dist/Utils";
import ZTooltip from "@zomentum/atoms/dist/ZTooltip";
import { InputNumberProps } from "antd/lib/input-number";
import React from "react";
import { DraggableLocation } from "react-beautiful-dnd";
import { Redirect, Route } from "react-router-dom";
import { cache } from "@zomentum/contracts";
import { useUserReady } from "./useUserReady";
import { shortenText } from "@zomentum/utils/dist/common";

export const isRouteAllowed = (
	_route: ZomentumRoutes,
	_role?: VendorUserRole | null,
	_customRolesFlag?: boolean,
	_enabledPermissions?: string[]
): boolean => {
	return true;
};

export const PrivateRoute = React.memo(
	({ component: PrivateComponent, ...routeProps }: any) => {
		const [userReady] = useUserReady();
		const authToken = cache.get(
			ZomentumStorageKeys.Token,
			true
		) as AuthToken;

		const userRoleAllowedToAccessRoute = isRouteAllowed(
			routeProps.path as ZomentumRoutes,
			userReady?.userData?.role
		);

		return (
			<Route
				{...routeProps}
				render={props =>
					authToken && authToken.token ? (
						<>
							{userRoleAllowedToAccessRoute ? (
								<PrivateComponent {...props} />
							) : (
								<Redirect to={ZomentumRoutes.Unauthorized} />
							)}
						</>
					) : (
						<Redirect to={ZomentumRoutes.Login} />
					)
				}
			/>
		);
	}
);

export const PublicRoute = ({
	component: PublicComponent,
	...routeProps
}: any) => {
	const routeLinkProps = { ...routeProps };
	let componentProps = {};
	if (
		Object.prototype.hasOwnProperty.call(routeLinkProps, "componentProps")
	) {
		componentProps = routeLinkProps.componentProps;
		delete routeLinkProps.componentProps;
	}
	if (PublicComponent) {
		return (
			<Route
				{...routeLinkProps}
				render={props => (
					<PublicComponent {...props} {...componentProps} />
				)}
			/>
		);
	}
	return <Route {...routeLinkProps} />;
};

export const LoginRoute = ({
	component: LoginComponent,
	...routeProps
}: any) => {
	const routeLinkProps = { ...routeProps };
	let componentProps = {};
	if (
		Object.prototype.hasOwnProperty.call(routeLinkProps, "componentProps")
	) {
		componentProps = routeLinkProps.componentProps;
		delete routeLinkProps.componentProps;
	}
	const authToken = cache.get(ZomentumStorageKeys.Token, true) as AuthToken;

	const routeToRedirect = DefaultPartnerAlignPrivateRoute;
	return (
		<Route
			{...routeLinkProps}
			render={props =>
				!authToken || !authToken.token ? (
					<LoginComponent {...props} {...componentProps} />
				) : (
					<Redirect to={routeToRedirect} />
				)
			}
		/>
	);
};

/**
 *
 * @param arr1 the base array.
 * @param arr2 the modification array, the values from this array would override the value of base array
 * @param trimFromBase will trim on base element
 */
export const mergeArray = (
	arr1: (string | null)[],
	arr2: (string | null)[],
	trimFromBase = false
) =>
	new Array(trimFromBase ? arr1.length : Math.max(arr1.length, arr2.length))
		.fill(true)
		.map((_v, i) => arr1[i] || arr2[i] || null);

export const checkValidImageURL = (url: string): boolean => {
	const extRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
	return !!url.match(extRegex);
};

export const oppValuesFormatter = (value = 0, decimals: number) => {
	if (!value) {
		return 0;
	}
	return parseFloat(Number(value).toFixed(decimals));
};

export const percentageInputProps: InputNumberProps = {
	formatter: (value: string | number | undefined): string =>
		Number(value ? value : 0).toString() + " %",
	parser: (value: string | undefined): number | string =>
		(value && Number(value) ? Number(value) : 0).toString(),
	min: 0,
	max: 100
};

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (
	source: any[],
	destination: any[],
	droppableSource: DraggableLocation,
	droppableDestination: DraggableLocation
) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result: Record<string, any[]> = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

export const checkCanMultiplePricingPlansFor = (
	productType: ProductType | null
): boolean => {
	return (
		!!productType &&
		![ProductType.Bundle, ProductType.Hardware].includes(productType)
	);
};

/**
 *
 * @param url url to extract domain
 */
export const extractHostname = (url: string) => {
	let hostname = new URL(url).hostname;
	// find & remove www
	hostname =
		hostname.indexOf("www.") > -1 ? hostname.split("www.")[1] : hostname;
	// find & remove port number
	hostname = hostname.split(":")[0];
	// find & remove "?"
	hostname = hostname.split("?")[0];
	return hostname;
};

export const getErrorMessageForIncompleteForm = (json: any): string => {
	const result: Record<string, string> = {};

	Object.keys(json).forEach(key => {
		typeof json[key] === "object"
			? (result[key] = getErrorMessageForIncompleteForm(json[key]))
			: (result[key] = json[key]);
	});

	return Object.values(result)[0];
};

export const preventDefault = (
	event: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
	return event.preventDefault();
};

export const millimeterToPixel = (x: number) => {
	const div = document.createElement("div");
	div.style.display = "block";
	div.style.width = `${x}mm`;
	document.body.appendChild(div);
	const px = parseFloat(window.getComputedStyle(div, null).width);
	div?.parentNode?.removeChild(div);
	return px;
};

export const formLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 19 }
};

export const getApprovalRuleFitlers = (
	documentApproval: DocumentApproval
): string[] => {
	const filters: string[] =
		documentApproval.filter_criteria
			?.map(filter => {
				if (
					!!filter?.filter_operator?.id_value?.length &&
					!!filter?.filter_operator?.display_value?.length
				) {
					return `${filter.field_display_name} ${
						filter.filter_operator.display_value
					} ${
						filter.values.length > 1
							? filter.values
									?.slice(0, -1)
									.map(v => v.display_value)
									.join(", ") +
							  " and " +
							  filter.values?.slice(-1)[0].display_value
							: filter.values.map(v => v.display_value).join("")
					}`;
				}
				return [];
			})
			.flat() ?? [];

	return filters;
};

export const getAllApprovers = (
	documentApprovalRules: DocumentApproval[],
	companyUserList: UserData[]
): string[] => {
	const allAdmins: string[] = companyUserList
		.filter(
			currentUser =>
				currentUser?.role && AdminRoleOnly?.includes(currentUser?.role)
		)
		.map(user => user.id);

	const allApprovers: string[] = documentApprovalRules.reduce(
		(ids: string[], current: DocumentApproval) => {
			if (
				current.approver_automation_details.document_approver_type ===
				DocumentApproverType.AllAdmins
			) {
				ids.push(...allAdmins);
			} else {
				if (current.approver_automation_details.approver_user_ids) {
					ids.push(
						...current.approver_automation_details.approver_user_ids
					);
				}
			}

			return ids;
		},
		[]
	);

	return allApprovers;
};

export const canCurrentUserApprove = (
	document: Document,
	userData: UserData,
	allApprovers: string[]
): boolean => {
	const isAdminDocument =
		document?.user?.role && AdminRoleOnly?.includes(document?.user?.role);

	const isCurrentUserAdmin =
		userData?.role && AdminRoleOnly?.includes(userData?.role);

	const isCurrentAdminApprover =
		userData.id &&
		document?.approval_required &&
		allApprovers.includes(userData.id) &&
		isCurrentUserAdmin;

	const isDocumentCreatedByAdmin =
		document &&
		!!document.approval_required &&
		document.user.id === userData.id &&
		isAdminDocument;

	return !!(isCurrentAdminApprover || isDocumentCreatedByAdmin);
};

export const showApprovalWarningEndClient = (document?: Document | null) => {
	return (
		!!document &&
		!!getQueryParamAuthDetails() &&
		document?.approval_required &&
		!!document?.revisions?.[0] &&
		document?.revisions?.[0]?.approval_details.approval_status !==
			ApprovalStatus.Approved
	);
};

export const dateCompare = (a: Date | null, b: Date | null): number =>
	(!!a ? new Date(a).getTime() : 0) - (!!b ? new Date(b).getTime() : 0);

export const roundTwoDecimals = (amount = 0): number => {
	if (!amount) return 0;
	const result = Math.round((Number(amount) + Number.EPSILON) * 100) / 100;
	return isNaN(result) ? 0 : result;
};

export const compareEtilizeRegion = (
	distributorRegion: string,
	etilizeRegion: EtilizeLocale
) => {
	distributorRegion = distributorRegion.toLowerCase();
	const replacedRegion =
		Object.entries(SimilarEtilzieRegions).find(
			region => region[0] === distributorRegion
		)?.[1] ?? distributorRegion;
	return `en_${replacedRegion}` === etilizeRegion;
};

export const validateEtilizeContentSource = (
	distributorRegion: string | null | undefined,
	userCompany: UserCompany
): boolean => {
	if (!!distributorRegion) {
		return compareEtilizeRegion(
			distributorRegion,
			userCompany.settings?.etilize_locale ?? EtilizeLocale.US
		);
	}
	return false;
};

export const isEtilizeVendorProductId = (vendor_product_id: string) => {
	return vendor_product_id.includes("etilize");
};

export const handleActivityLinkClick = (
	messageType: ActivityMessageType,
	value: string
) => {
	if (
		messageType === ActivityMessageType.REDIRECT &&
		value &&
		value.length > 0
	) {
		window.open(value);
	}
};

export const formActivityText = (
	activities: ActivityMessage[],
	shouldShortenText = true
) => {
	const textActivity: ActivityMessage = {
		text: "",
		message_type: ActivityMessageType.TEXT,
		value: null
	};

	const activityMessageArray = activities.reduce(
		(
			final: ActivityMessage[],
			activity: ActivityMessage,
			index: number
		) => {
			if (activity.message_type === ActivityMessageType.TEXT) {
				textActivity.text = textActivity.text + " " + activity.text;
			} else {
				final.push({ ...textActivity });
				textActivity.text = "";
				final.push(activity);
			}

			if (index === activities.length - 1 && !!textActivity.text) {
				final.push(textActivity);
			}

			return final;
		},
		[]
	);

	return activityMessageArray.map(({ message_type, text, value }, index) =>
		message_type === ActivityMessageType.TEXT || !value ? (
			<span key={index} className="mr-1">
				{shouldShortenText ? shortenText(text, 45) : text}
			</span>
		) : (
			value && (
				<ZTooltip title={text} key={index}>
					<span
						className="link"
						onClick={() =>
							handleActivityLinkClick(message_type, value)
						}
						key={index}
					>
						{shouldShortenText ? shortenText(text, 45) : text}
					</span>
				</ZTooltip>
			)
		)
	);
};

export const isEtilizeFiltersDropped = (
	productSearchRequest: ProductSearchRequest,
	productsSearchResponse: ProductSearchResponse
) => {
	if (
		productSearchRequest.filter_by_item_sources.includes(
			ProductSource.Etilize
		)
	) {
		const requestedFiltersCount =
			productSearchRequest.filter_by_categories.length +
			productSearchRequest.filter_by_manufacturers.length;
		const recievedFiltersCount =
			productsSearchResponse.meta_info.categories.length +
			productsSearchResponse.meta_info.manufacturers.length;
		return recievedFiltersCount < requestedFiltersCount;
	}
	return false;
};

export const isPayableCurrency = (currency: string): boolean => {
	const cbSupportCurrencies = Object.values(CBSupportedCurrency).map(
		supportedCurrency => supportedCurrency as string
	);

	return cbSupportCurrencies.some(
		supportedCurrency => supportedCurrency === currency
	);
};

export const validateEmails = (emails: string[]) => {
	return emails.every(
		email =>
			email.match(emailRegex) || email === "owner" || email === "contacts"
	);
};

export const isStringAnInteger = (str: string) => {
	if (!str) {
		return false;
	}

	// RegEx replacement for escaping Zero(s) before the number
	str = str.replace(/^0+/, "") || "0";
	const n = Math.floor(Number(str));
	return n !== Infinity && String(n) === str && n >= 0;
};

export const toTitleCase = (str: string): string => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export const sumTotal = (
	a: QuoteTotal | null,
	b: QuoteTotal | null
): QuoteTotal | null => {
	if (!a || !b) return b || a;
	return {
		one_time: a.one_time + b.one_time,
		quarterly: a.quarterly + b.quarterly,
		monthly: a.monthly + b.monthly,
		weekly: a.weekly + b.weekly,
		annual: a.annual + b.annual,
		grand: a.grand + b.grand,
		semi_annually: a.semi_annually + b.semi_annually
	};
};

export const handleRegionTextIngram = (value: string): string => {
	switch (value) {
		case DistributorRegions.USA:
			return `${IngramRegions.us}`;
		case DistributorRegions.UK:
			return `${IngramRegions.uk}`;
		case DistributorRegions.Australia:
			return `${IngramRegions.au}`;
		case DistributorRegions.Canada:
			return `${IngramRegions.ca}`;
		case DistributorRegions.NZ:
			return `${IngramRegions.nz}`;
		case DistributorRegions.AT:
			return `${IngramRegions.at}`;
		case DistributorRegions.DE:
			return `${IngramRegions.de}`;
		case DistributorRegions.SE:
			return `${IngramRegions.se}`;
		case DistributorRegions.CH:
			return `${IngramRegions.ch}`;
		case DistributorRegions.HU:
			return `${IngramRegions.hu}`;
		case DistributorRegions.ES:
			return `${IngramRegions.es}`;
		case DistributorRegions.FR:
			return `${IngramRegions.fr}`;
		case DistributorRegions.IT:
			return `${IngramRegions.it}`;
		case DistributorRegions.PT:
			return `${IngramRegions.pt}`;
		case DistributorRegions.Netherlands:
			return `${IngramRegions.nl}`;
		default:
			return "";
	}
};
export const dropdownPlacements = {
	bottomLeft: {
		points: ["tl", "bl"],
		offset: [0, 4],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	},
	topLeft: {
		points: ["bl", "tl"],
		offset: [0, -4],
		overflow: {
			adjustX: 0,
			adjustY: 1
		}
	}
};

export const getDocumentDateTimePattern = (
	document: Document | null,
	datePattern: string
) => {
	return datePattern.trim().length
		? datePattern
		: document?.user?.user_settings?.date_pattern?.trim().length
		? document?.user?.user_settings?.date_pattern
		: document?.company?.settings?.date_pattern ?? "";
};

export const getInitialTaxCategoryId = (
	defaultData:
		| AddQuoteProductCatalogDrawerFormValues
		| EditQuoteProductCatalogDrawerFormValues
		| null,
	taxCategoriesList: TaxCategory[],
	currentActiveDocument: Document | null
): string | undefined => {
	if (currentActiveDocument?.tax_region_id) {
		if (!!defaultData?.tax_category_id?.length) {
			const defaultTaxCategory = taxCategoriesList.find(
				taxCategory => taxCategory.id === defaultData.tax_category_id
			);
			if (!defaultTaxCategory || defaultTaxCategory?.disabled) {
				return;
			}
			return defaultData?.tax_category_id;
		} else if (defaultData?.product_source === ProductSource.Etilize) {
			return taxCategoriesList?.find(
				taxCategory =>
					!taxCategory.disabled &&
					taxCategory.default_for_types?.includes(
						ProductType.Hardware
					)
			)?.id;
		} else if (!!defaultData?.product_type?.length) {
			return taxCategoriesList?.find(
				taxCategory =>
					!taxCategory.disabled &&
					taxCategory.default_for_types?.includes(
						defaultData?.product_type as ProductType
					)
			)?.id;
		}
	} else {
		if (defaultData?.product_source === ProductSource.Etilize) {
			return taxCategoriesList?.find(
				taxCategory =>
					!taxCategory.disabled &&
					taxCategory.default_for_types?.includes(
						ProductType.Hardware
					)
			)?.id;
		} else if (!!defaultData?.product_type?.length) {
			return taxCategoriesList?.find(
				taxCategory =>
					!taxCategory.disabled &&
					(taxCategory.default_for_types?.includes(
						defaultData?.product_type as ProductType
					) ||
						taxCategory.id === defaultData?.tax_category_id)
			)?.id;
		}
	}
	return;
};
